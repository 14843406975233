<template>
  <b-container>
    <b-row class="text-start p-4">
      <b-col lg="4" cols="12" class="d-flex justify-content-between" v-if="selected === 'profileInfo'">
        <curva-title :title="$t('profile.info')"></curva-title>
      </b-col>
      <b-col lg="8" cols="12" class="d-flex justify-content-end" v-if="selected === 'profileInfo'">
        <span class="p-1">
          <router-link :to="{name:'changePassword'}">
            <button class="curva-icon-btn change-pass">{{ $t('profile.changePass') }}</button></router-link>
          <router-link :to="{name:'editProfile'}">
            <button class="curva-icon-btn"><i class="las la-pen"></i> {{ $t('profile.editProfile') }}</button></router-link>
        </span>
      </b-col>
      <b-col lg="12" class="d-flex justify-content-between" v-else-if="selected === 'addressBook'">
        <curva-title :title="$t('profile.address')"></curva-title>
        <router-link :to="{name:'addAddress'}">
          <button class="curva-icon-btn"><i class="las la-pen"></i> {{ $t('profile.addAddress') }}</button></router-link>
      </b-col>
      <b-col lg="12" class="" v-else-if="selected === 'myOrders'">
        <curva-title :title="$t('profile.orders')"></curva-title>
      </b-col>
      <b-col lg="12" class="" v-else-if="selected === 'wishlist'">
        <curva-title :title="$t('profile.wishlist')"></curva-title>
      </b-col>
      <b-col lg="12" class="" v-else-if="selected === 'changePassword'">
        <curva-title :title="$t('profile.changePass')"></curva-title>
      </b-col>
      <b-col lg="12" class="" v-else-if="selected === 'editProfile'">
        <curva-title :title="$t('profile.editProfile')"></curva-title>
      </b-col>
      <b-col lg="12" class="" v-else-if="selected === 'addAddress'">
        <curva-title :title="$t('profile.addAddress')"></curva-title>
      </b-col>
      <b-col lg="12" class="d-flex justify-content-between" v-if="selected === 'orderDetails'">
        <curva-title :title="$t('profile.orderDetails')"></curva-title>
        <span>
          <router-link><button class="curva-icon-btn mr-4">{{ $t('profile.rEdit') }}</button></router-link>
          <router-link>
            <button class="curva-remove-btn">{{ $t('profile.cancel') }}</button></router-link>
        </span>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="3">
        <ul class="curva-list iq-style4 nav-list p-0">
          <li class="grey-border">
            <router-link :to="{name:'profileInfo'}" exact><i class="las la-user"></i>
              {{ $t('profile.info') }}</router-link>
          </li>
          <li class="grey-border">
            <router-link :to="{name:'addressBook'}"><i class="las la-address-book"></i>
              {{ $t('profile.address') }}</router-link>
          </li>
          <li class="grey-border">
            <router-link :to="{name:'myOrders'}"><i class="las la-book"></i>
              {{ $t('profile.orders') }}</router-link>
          </li>
          <li class="grey-border">
            <router-link :to="{name:'wishlist'}"><i class="lar la-heart"></i>
              {{ $t('profile.wishlist') }}</router-link>
          </li>
        </ul>
      </b-col>
      <b-col lg="9">
        <router-view>
        </router-view>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  data () {
    return {
      selected: 'profileInfo'
    }
  },
  watch: {
    $route (to, from) {
      console.log(to)
      this.selected = to.name
    }
  },
  created () {
    this.selected = this.$route.name
  },
  mounted () {
    core.index()
  }
}
</script>
